.Web {
  padding-top: 130px;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  .Section-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    height: 45vh;
    background: rgb(255, 255, 255);
    padding: 2%;

    iframe {
      -webkit-animation: Gauche_Droite 2s ease-out;
      animation: Gauche_Droite 2s ease-out;
    }

    p {
      width: 75%;
      color: $color-3;

      line-height: 1.7em;
      font-family: $font-5;
      font-size: clamp(0.2rem, 1.5vw, 1.5rem);
      font-weight: 100;
      max-height: 250px;
      -webkit-animation: Droite_Gauche 2s ease-out;
      animation: Droite_Gauche 2s ease-out;

      &::first-letter {
        font-weight: 500;
        text-decoration: underline;
      }
    }

    h1 {
      color: $color-5;
      text-transform: capitalize;
      font-weight: 900;
      margin: 2vh 5vh 5vh;
      font-size: clamp(2.5em, 1vw, 4em);
      -webkit-animation: Haut_Bas 2s ease-out;
      animation: Haut_Bas 2s ease-out;

      &::after {
        content: ".";
        color: $color-1;
      }
    }

    .left {
      height: 100%;
      width: 50%;
    }

    .right {
      height: 80%;
      width: 50%;
      border-left: 1px dotted $color-5;
      margin: auto;
    }
  }

  .Section-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 40vh;
    background: rgb(0, 0, 0);
    margin: 2%;

    p {
      width: 75%;
      color: $color-7;

      line-height: 1.7em;
      font-family: $font-5;
      font-size: clamp(0.2rem, 1.5vw, 1.5rem);
      font-weight: 100;
      max-height: 250px;

      -webkit-animation: Droite_Gauche 2s ease;

      animation: Droite_Gauche 2s ease;

      &::first-letter {
        font-weight: 900;
        text-decoration: underline;
      }
    }

    h1 {
      color: $color-5;
      text-transform: capitalize;
      font-weight: 900;
      margin: 5vh 5vh 5vh;
      font-size: clamp(2.5em, 1vw, 4em);
      -webkit-animation: Gauche_Droite 2s ease;
      animation: Gauche_Droite 2s ease;

      &::after {
        content: ".";
        color: $color-1;
      }
    }
    .left {
      height: 100%;
      width: 50%;
      padding-left: 10vw;
    }

    .right {
      height: 100%;
      width: 50%;
      -webkit-animation: Droite_Gauche 3s ease;
      animation: Droite_Gauche 3s ease;
    }
  }

  .petit-film,
  .film-internet {
    width: 100%;
    height: 100%;
    border: none;
    // -o-object-fit: scale-down;
    // object-fit: scale-down;
  }

  .Photo-e-commerce {
    height: 100%;
    border: none;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    margin-left: 15%;
  }

  .Section-3 {
    padding: 5% 15%;
    max-height: 65vh;
    background: $color-8;

    h3 {
      text-transform: uppercase;
      color: $color-1;
      text-align: center;
      font-family: $font-2;
      font-size: clamp(0.5rem, 1.5vw, 0.8rem);
      letter-spacing: 5px;
    }
    h2 {
      text-transform: uppercase;
      color: $color-3;
      text-align: center;
      font-family: $font-5;
      font-size: clamp(1rem, 1.5vw, 10rem);
      font-weight: 500;
    }
    .sous-titre {
      text-transform: uppercase;
      color: $color-3;
      text-align: center;
      font-family: $font-5;
      font-size: clamp(0.5rem, 1.5vw, 0.8rem);
      font-weight: 500;
    }
  }

  @-webkit-keyframes Haut_Bas {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes Haut_Bas {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @-webkit-keyframes Droite_Gauche {
    0% {
      opacity: 0;
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes Droite_Gauche {
    0% {
      opacity: 0;
      -webkit-transform: translateX(500px);
      transform: translateX(500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes Gauche_Droite {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-500px);
      transform: translateX(-500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes Gauche_Droite {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-500px);
      transform: translateX(-500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes Opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes Opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1000px) {
  .Web {
    padding: 15% 0;

    .Section-1,
    .Section-2 {
      padding: 0 5%;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 0;

      p {
        font-size: 1rem;
        width: 75%;
        margin-bottom: 25px;
      }

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        border-left: 0px dotted $color-5;
      }
      img {
        width: 80%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
