.Contact {
  // padding: 0 8%;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  .Image-Fond {
    width: 100%;
    height: 65vh;
    margin: 0px 0px -50px 0px;
    object-fit: cover;
  }

  .degrade {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(4, 0, 0, 0.1) 80%,
      transparent 100%
    );
    z-index: 1;
  }

  .Form-Contact {
    width: 100%;
    background: $color-2;
    padding: 9% 15%;
  }

  .titre-1-contact {
    font-family: $font-1;
    letter-spacing: 5px;
    color: $color-3;
  }
  .titre-2-contact {
    font-family: $font-4;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 2.5em;
    color: $color-3;
    padding-top: 25px;

    &::after {
      content: ".";
      color: $color-1;
    }

    &::first-letter {
      text-decoration: underline;
    }
  }

  .containt-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 65px;
    border-radius: 10px;
    width: 100%;

    box-shadow: 0px 50px 80px 10px rgba(0, 0, 0, 0.21);
  }

  .titre-1-form {
    font-family: $font-1;
    letter-spacing: 5px;
    color: $color-3;
  }

  .left {
    font-size: 16px;
    padding: 5%;
    border-right: 2px dotted $color-5;
    margin: 50px 0;

    h3 {
      margin-bottom: 50px;
    }

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(1fr * 3);
      grid-template-areas:
        "i1 i2"
        "ta ta"
        "bt bt";
      gap: 5px;
    }

    button {
      grid-area: bt;
      grid-column: span 2;
      margin-top: 5px;
      background-color: $color-1;
      color: $color-2;
      text-decoration: none;
      border: 0;
      border-radius: 50px;
      height: 40px;
      font-family: $font-4;
      font-size: 0.7em;
    }

    #user_nom,
    #user_email,
    #message {
      border: none;
      background-color: $color-8;
      border-radius: 5px;
      padding: 15px;
      font-family: $font-1;
      font-size: 1em;
    }

    #user_nom {
      grid-area: i1;
    }

    #user_email {
      grid-area: i2;
    }

    #message {
      grid-area: ta;
      text-decoration: none;
      min-height: 250px;
      resize: none;
    }

    .adresse-email {
      margin: 90px 0 0;
      display: flex;
      font-family: $font-4;
      font-size: 1em;
      color: $color-4;
    }
    .adresse-email p {
      margin: 5px;
    }
    .adresse-email p:first-child {
      color: $color-1;
    }
  }

  .right {
    margin: auto;
    padding: 5%;
    font-size: 16px;

    h3 {
      margin: 30px 0;
      font-size: 1.5em;
      font-family: $font-4;
      font-weight: 100;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    p {
      font-family: $font-4;
      font-size: 0.9em;
      font-weight: 100;
      color: $color-4;
      margin: 70px 0;
      text-align: center;
    }
  }

  .message {
    margin: 15px 5px;
    color: $color-5;
    font-family: $font-1;
  }
}

@media screen and (max-width: 1280px) {
  .Contact {
    .Form-Contact {
      padding: 9% 5%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .Contact {
    .Image-Fond {
      height: 50vh;
      margin-bottom: -3px;
    }

    .containt-form {
      grid-template-columns: 1fr;
    }

    .Form-Contact {
      width: 100%;
      padding: 5%;
    }

    .left {
      border-right: 0px dotted $color-5;
      form {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1fr * 4);
        grid-template-areas:
          "i1"
          "i2"
          "ta"
          "bt";
      }
    }
  }
}
