.curseur {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 30px;
  height: 30px;
  border: 1px dashed $color-2;
  pointer-events: none;
  border-radius: 50%;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: $color-1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &.hover {
    animation: Anim 0.5s;
    transform: scale(0.8);
    border: 2px solid $color-2;

    &::before {
      content: "";
      background-color: transparent;
    }
  }
}

@keyframes Anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
