.logo {
  display: block;
  margin-left: 5vw;

  .imglogo {
    margin-left: -100%;
    // margin-top: -22px;
    padding: 5px;
    width: 100px;
    object-fit: cover;
    transform: scale(300%);
    transition: 1ms ease;
  }
}

@media screen and (max-width: 1200px) {
  .logo {
    // margin-top: -15px;
    .imglogo {
      width: 70px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .logo {
    display: none;
  }
}
