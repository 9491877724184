.Image {
  align-items: center;
  text-align: center;
  border: none;
  padding-top: 120px;

  img {
    max-width: 100vw;
  }
}

.nav {
  width: 100%;
  position: fixed;
}
