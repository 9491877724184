/************  LOGOS DE REFERENCE *****************/

.img-ref {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  place-items: center;
  overflow: hidden;
  border-right: $color-4 2px solid;
  margin-top: 5px;
}

.img-ref::before,
.img-ref::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 1;
  background: linear-gradient(to right, $color-4 0%, rgba(0, 0, 0, 0) 100%);
}

.img-ref::before {
  left: 0;
  top: 0;
}

.img-ref::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.img-wrap {
  margin: 30px;
  animation: scrollRef 50s linear infinite;
  perspective: 100px;
  img {
    transition: 0.5s ease-in-out;
    width: 150px;
  }

  img:hover {
    transform: translateZ(20px);
  }
}

@keyframes scrollRef {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 25));
  }
}

@media screen and (max-width: 1000px) {
  .img-ref {
    display: none;
  }
}
