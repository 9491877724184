.Production {
  .Entete {
    padding-top: 130px;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(4, 0, 0, 0.3) 36%,
      transparent 100%
    );
    overflow: hidden;
  }

  .Entete::after {
    content: "";
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 50vh;
    position: absolute;
    background: linear-gradient(
      0deg,
      $color-4 0%,
      rgba(1, 0, 0, 0) 100%,
      transparent 100%
    );
  }

  .videofond {
    position: relative;
    // filter: opacity(80%);
    filter: brightness(0.8);
    // filter: blur(0.5rem);
    z-index: -1;
  }
  .Film-Fond {
    width: 100%;
    height: 42.86vw; /* 9 / 21 * 100 = 42,86*/
    min-height: 100vh;
    min-width: 233.33vh; /* 21 / 9 * 100 = 233,333*/
    position: absolute;
    top: -150px;
    left: -15px;
    border: none;
  }

  .GrosTitre-Production {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 50px;
    padding-left: 8%;

    h1 {
      margin-left: 10%;
      padding: 0px;
      font-size: 3rem;
      font-weight: 800;
      font-family: $font-4;
      text-transform: capitalize;
      animation: transitionHB 2s ease-out;
    }
    .SousTitre {
      margin-top: 50px;
      display: flex;
      animation: transitionHB 3s ease-out;

      .GrosTitre2,
      .OptionCouleur {
        color: $color-5;
        font-size: clamp(35px, 7vw, 90px);
        font-weight: 900;
      }
      .OptionCouleur {
        color: $color-1;
        margin: 0;
      }
    }
  }

  @keyframes transitionHB {
    0% {
      opacity: 0;
      transform: translateY(-200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .films,
  .images {
    margin: 15px 50px;
    h1 {
      margin-left: 30px;
      font-size: 5em;
    }

    .bloc-onglets {
      display: flex;
      justify-content: flex-end;
      margin-right: 2%;
    }

    .tabs,
    .tabs-active {
      background-color: $color-3;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 3px;
      font-weight: 100;
      font-family: $font-4;
      text-transform: capitalize;
      cursor: pointer;
    }

    .tabs-active {
      background-color: $color-1;
    }

    .content-films {
      margin: 15px auto 100px;
      padding: 0 20px;
      max-width: 1800px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 430px);
      grid-gap: 10px;
      justify-content: center;
    }

    .card {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
    }

    .Play {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5em;
      padding: auto;
      text-align: center;
    }

    .NomFilm {
      position: absolute;
      margin: 0;
      padding: 15px;
      font-size: 0.8em;
      top: 0;
      left: 0;
      width: 100%;
      height: 96%;
      border-radius: 10px;
      color: transparent;

      p {
        margin: 0 5px;
      }
    }

    .NomFilm:hover {
      background: rgba(119, 0, 0, 0.444);
      color: $color-2;

      transition: color 0.5s ease;

      span {
        color: rgba(255, 255, 255, 0.289);
        color: rgba(255, 0, 0, 0.602);
      }
    }
  }

  .films {
    .card iframe {
      border: 1px solid $color-3;
      width: 100%;
      height: 182px;
      border-radius: 10px;
      object-fit: cover;
    }
    .card img {
      border: 1px solid $color-3;
      width: 100%;
      height: 182px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .images {
    .content-images {
      max-width: 1760px;
      // padding: 0px 60px;
      margin: 20px auto;
      display: flex;
      flex-wrap: wrap;
    }

    .Gallery {
      column-count: 3;
    }

    .card img {
      border: 1px solid $color-3;
      width: 100%;
      margin-bottom: 8px;
      border-radius: 10px;
    }

    .NomImage {
      position: absolute;
      margin: 0;
      padding: 15px;
      font-size: 0.8em;
      top: 0;
      left: 0;
      width: 100%;
      height: 99%;

      border-radius: 10px;
      color: transparent;

      p {
        margin: 0 5px;
      }
    }

    .NomImage:hover {
      background: rgba(119, 0, 0, 0.444);
      color: $color-2;
      transition: color 0.5s ease;
    }
    h1 {
      font-size: 5em;
    }
  }
}

@media screen and (max-width: 1000px) {
  .Production {
    .Entete {
      overflow: hidden;
    }
    .bloc-onglets {
      margin-right: 0;
    }
    .videofond {
      position: relative;
      width: 100%;
      height: auto;

      .Film-Fond {
        position: absolute;
        width: 100vh;

        height: auto;

        top: -150px;
        left: -15px;
        border: none;
      }
    }

    .films,
    .images {
      margin: 0;
      h1 {
        margin-left: 5%;
        font-size: 5em;
      }
    }
    .GrosTitre-Production {
      h1 {
        font-size: 2rem;
        margin-left: 1%;
      }
    }
  }
}
