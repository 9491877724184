/********* DESCRIPTION PRESTATIONS *************/
.descriptionHome {
  margin: 150px 0 200px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.desc-1,
.desc-2,
.desc-3 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;

  img {
    width: 100%;
    margin: auto;
    padding-bottom: 8%;
  }
}

.icone {
  -webkit-transform: scale(60%);
  -ms-transform: scale(60%);
  transform: scale(60%);
}

.titre {
  top: clamp(-30px, 1vw, -50px);
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  font-size: clamp(15px, 1vw, 16px);
}

.titre::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 1.5px;
  background-color: $color-3;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -32px;
}

.detail {
  font-size: clamp(12px, 1vw, 16px);
  font-family: $font-4;
  font-weight: 100;
  text-align: center;
  line-height: 30px;
}

@media screen and (min-width: 1500px) {
  .desc-1,
  .desc-2,
  .desc-3 {
    max-width: 550px;
  }
}

@media screen and (max-width: 1000px) {
  .descriptionHome {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .desc-1,
  .desc-2,
  .desc-3 {
    width: 100%;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    img {
      display: inline;
      height: auto !important;
      max-width: 100%;
      width: auto;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      border-radius: inherit;
    }
  }
}
