.Cartes {
  padding: 0 15%;
  width: 100%;
  background: $color-7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .Carte,
  .Carte-MediaSociaux {
    display: flex;
    flex-direction: column;
    margin: 25px 25px;
    box-shadow: 3px 10px 15px 0px #88888897;
    width: 500px;
    height: 200px;
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4.5%;
    justify-content: center;
    align-items: center;

    .Photo {
      grid-column: 1;
      width: 100%;
      filter: saturate(0);
    }

    .Text_Carte {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }

    .Titre {
      grid-column: 2;
      color: $color-4;
      font-size: x-large;
      margin-bottom: 5px;
      font-family: $font-1;
    }

    .SousTitre {
      grid-column: 2;
      color: $color-8;
      font-size: small;
      margin-bottom: 15px;
      font-family: $font-1;
    }
    .Text {
      grid-column: 2;
      color: $color-5;
      font-family: $font-1;
      font-size: clamp(0.6rem, 1.5vw, 1rem);
    }
  }

  .Carte-MediaSociaux {
    height: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .Cartes {
    padding: 0;
  }
}
