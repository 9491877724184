.home {
  .big-screen {
    padding-top: 150px;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(4, 0, 0, 0) 100%,
      transparent 100%
    );
  }

  //*********** DEGRADER NOIR BAS DE PAGE **********
  .vimeo-wrapper::after {
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    background: linear-gradient(
      0deg,
      $color-4 0%,
      rgba(4, 0, 0, 0) 100%,
      transparent 100%
    );
  }

  .vimeo-wrapper {
    // max-height: 1024px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
  }

  .vimeo-wrapper iframe,
  .vimeo-wrapper video,
  .vimeo-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    overflow: hidden;
    width: 100%;
    height: 56.25vw; /* 9 / 16 * 100 = 56.25*/
    min-height: 100vh;
    min-width: 177.78vh; /* 16 / 9 * 100 = 177.78*/
  }

  .vimeo-wrapper iframe {
    animation: Opacity 15s ease;
  }
  // .vimeo-wrapper img {
  //   animation: Opacity 5s reverse ease;
  // }

  .vimeo-wrapper video {
    animation: Opacity 5s ease;
  }

  @keyframes Opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .GrosTitre {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 50px;
    padding-left: 8%;

    h1 {
      margin: 0px;
      padding: 0px;
      font-size: clamp(25px, 7vw, 80px);
      font-weight: 900;
    }
    .GrosTitre2 {
      color: $color-1;
    }
  }

  .PetitTitre {
    font-size: calc(2vmin + 3px);
    font-family: $font-4;
    font-weight: 300;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 50px;
    width: 100%;
    margin-top: 5%;
    padding-left: 8%;
    padding-right: 8%;
  }

  .PetitTitre1 {
    grid-column: 1;
    grid-row: 1/3;
    display: flex;
    align-items: center;
  }

  .PetitTitre2 {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: $color-1;
    // transform: translateY(50%);
  }

  .PetitTitre3 {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .small-screen {
    position: relative;
    display: none;

    .section-1 {
      height: 103vh;
      width: 80%;
      margin: auto;
      padding-top: 28vh;
    }
    .logo-burger {
      width: 100%;
    }

    .section-2 {
      .background-video {
        display: none;
      }

      height: auto;
      padding-bottom: 50px;
    }

    .PetitTitre1 {
      margin-bottom: 50px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .home {
    .vimeo-wrapper {
      display: none;
    }

    .big-screen {
      display: none;
    }

    .small-screen {
      display: block;

      .GrosTitre,
      .PetitTitre {
        display: block;
        z-index: 10;
      }

      .section-2 {
        position: relative;
        overflow: hidden;

        .background-video {
          display: block;
          position: absolute;
          width: 100vh;
        }
        .background-video iframe,
        .background-video video,
        .background-video img {
          position: absolute;
          height: 100vh;
          width: 200%;
          top: 0;
          left: -20%;
          border: none;
          z-index: -1;
        }

        .background-video iframe,
        .background-video video {
          transform: translateY(-50%);
          animation: Opacity 5s ease;
        }
        // .vimeo-wrapper img {
        //   animation: Opacity 5s reverse ease;
        // }
      }
    }

    .GrosTitre,
    .PetitTitre {
      display: none;
    }
  }
}
