/************** VIDEO ********************/
.PhotosVideosHome {
  overflow: hidden;
}

.grand-film {
  margin: 20px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  width: 59.3vw;
  height: 25vw;
  border-radius: 30px;
}

/************* PHOTOS ET FILMS ************/

.Petites-Photo-Film {
  display: grid;
  position: relative;
  margin: 2vh 0;
  width: 60vw;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  grid-gap: 1.5%;
  align-items: center;
  grid-template-columns: 1fr 1fr 2.3fr;
}

.Petite-Photo-1 {
  width: 100%;
  border-radius: 20px;
}

.petit-film-1 iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 0;
}

.petit-film-1 {
  display: block;
  border: none;
  width: 100%;
  height: auto;
  aspect-ratio: 80/34;
  border-radius: 20px;
}

/****************************/
.Petit-Film-photo {
  display: grid;
  position: relative;
  margin: 2vh 0;
  width: 60vw;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  grid-gap: 1.5%;
  align-items: center;
  grid-template-columns: 1fr 1.02fr;
}

.petit-film-2 {
  display: block;
  border: none;
  width: 100%;
  height: auto;
  aspect-ratio: 80/34;
  border-radius: 20px;
}
.Petite-Photo-3 {
  width: 100%;
  border-radius: 20px;
}

/****************************/
.Grande-Photo-photo-film {
  display: grid;
  position: relative;
  margin: 2vh 0;
  width: 60vw;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  grid-gap: 1.5%;
  align-items: center;
  // grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.Petite-Photo-4 {
  width: 100%;
  border-radius: 20px;
  grid-column: 1/1;
  grid-row: 1/3;
}
.Petite-Photo-5 {
  width: 100%;
  border-radius: 20px;
  grid-row: 1/1;
  grid-column: 2/2;
}

.petit-film-3 {
  display: block;
  border: none;
  width: 100%;
  height: auto;
  aspect-ratio: 80/34;
  border-radius: 20px;
  grid-row: 2/2;
  grid-column: 2/2;
}

@media screen and (max-width: 1000px) {
  .Petites-Photo-Film,
  .Petit-Film-photo,
  .Grande-Photo-photo-film,
  .grand-film {
    display: none;
  }
}
