@import url("https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Sulphur+Point:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Red+Hat+Display&family=Sulphur+Point:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;800;900&display=swap");

$font-1: "Red Hat Display", sans-serif;
$font-2: "Sulphur Point", sans-serif;
$font-3: "Open Sans Condensed", sans-serif;
$font-4: "Montserrat", sans-serif;
$font-5: "Roboto", sans-serif;

$color-1: #bd133b;
$color-2: #f7f7f7;
$color-3: #4d4c4d;
$color-4: rgb(1, 1, 1);
$color-5: #7a7a7a;
$color-6: rgb(32, 35, 42);
$color-7: #c4c4c4;
$color-8: #d6d7da;

::-webkit-scrollbar {
  background: $color-6;
}

::-webkit-scrollbar-thumb {
  background: $color-3;
}

::-webkit-scrollbar-corner {
  background: $color-4;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: $font-3;
  color: $color-2;
  background-color: $color-4;
  overflow-x: hidden;
  font-size: clamp(8px, 1vw, 16px);
  cursor: none;
}
li {
  list-style-type: none;
}
button,
#onglet {
  cursor: none;
}
a {
  text-decoration: none;
  color: $color-2;
  cursor: none;
  font-size: 25px;
}
a:visited {
  color: $color-2;
}

h1 {
  font-family: $font-4;
  font-weight: 900;
  text-transform: uppercase;
}

p {
  margin: 0 50px;
}

input,
textarea {
  &:focus {
    outline: 1px solid $color-5;
  }
}

@media screen and (max-width: 1000px) {
  .curseur {
    display: none;
  }

  body {
    cursor: default;
  }

  a,
  button,
  #onglet {
    cursor: pointer;
  }
}
