.sous-menu {
  position: absolute;
  top: 80%;
  text-align: start;
  justify-content: flex-start;
  color: $color-1;
  z-index: 50;
  width: 200%;
  left: 0px;
  padding: 0;
  background: $color-3;
  border-radius: 10px;
  border-top: 2px solid $color-1;

  a {
    display: block;
    font-size: 0.8em;
  }
}

.curseur .hover {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 30px;
  height: 30px;
  border: 1px dashed $color-2;
  pointer-events: none;
  border-radius: 50%;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: $color-1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.titre-sous-menu {
  border-top: 1px dotted white;
  justify-content: center;
  line-height: 2px;
  transition: 0.5s;
}
.Sous-nav-active {
  background-color: $color-1;
}

.titre-sous-menu:nth-child(1) {
  border: none;
}

.titre-sous-menu:hover {
  background-color: $color-5;
  color: $color-4;
  transition: 0.5s;
  border-radius: 5px;
}

@media screen and (max-width: 1000px) {
  .sous-menu {
    top: 42px;
    height: 200px;
    width: 150%;
    border-radius: 25px;
    a {
      padding: 15px;
    }
  }

  .titre-sous-menu {
    line-height: 20px;
  }

  .titre-sous-menu:hover {
    border-radius: 5px;
  }
}
