.ProdFilms {
  padding-top: 130px;
  width: 100%;
  height: 100vh;
  z-index: -1;

  .Section-1 {
    padding: 0 15%;
    height: 40vh;
    background: rgb(0, 0, 0);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 40px;
    grid-gap: 1.5%;
    align-items: center;

    p {
      animation: Droite_Gauche 2s ease;
    }
  }
  .titre1 {
    grid-row: 1;
    display: flex;
    font-size: clamp(0.2rem, 3vw, 2.5rem);
    text-transform: capitalize;
    font-weight: 900;
    font-family: $font-4;
    align-items: flex-end;
    max-height: 60px;
    color: $color-5;
    animation: Gauche_Droite 2s ease;
  }

  .titre1::after {
    content: ".";
    color: $color-1;
  }

  iframe {
    grid-row: 2;
    grid-column: 1;
    display: block;
    border: none;
    width: 95%;
    height: auto;
    max-width: 850px;
    aspect-ratio: 80/34;
    border-radius: 30px;
    animation: Gauche_Droite 2s ease;
  }
  p {
    margin: 0;
    grid-row: 2;
    grid-column: 2;
    font-family: $font-5;
    color: $color-7;
    // text-align: justify;
    font-size: clamp(0.2rem, 1.5vw, 1.5rem);
    font-weight: 100;
    max-height: 250px;
  }

  p::first-letter {
    font-weight: 500;
    text-decoration: underline;
  }

  .Section-2 {
    height: 50vh;
    background: rgb(255, 255, 255);
    padding: 0 15%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 40px;
    grid-gap: 1.5%;
    align-items: center;

    .titre2 {
      grid-column: 2;
      justify-content: center;
      transform: translateY(2vh);
      z-index: 10;
      grid-row: 1;
      display: flex;
      font-size: clamp(0.2rem, 3vw, 2.5rem);
      text-transform: capitalize;
      font-weight: 900;
      font-family: $font-4;
      align-items: flex-end;
      max-height: 60px;
      color: $color-5;

      animation: Opacity 2s ease;

      &::after {
        content: ".";
        color: $color-1;
      }
    }

    p {
      color: rgb(0, 0, 0);
      grid-row: 2;
      grid-column: 1;
      margin-top: -10vh;
      animation: Gauche_Droite 4s ease;
    }

    iframe {
      grid-row: 2;
      grid-column: 2;
      width: 80%;
      height: auto;
      max-width: 850px;
      aspect-ratio: 4/3;
      border-radius: 300px;
      transform: translate(80px, -50px);
      animation: Opacity 3s ease;
    }
  }

  .Section-3 {
    padding: 0 15%;
    height: 50vh;
    background: rgb(0, 0, 0);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 80px;
    grid-gap: 1.5%;
    align-items: center;

    iframe {
      width: 100%;
      height: auto;
      max-width: 850px;
      aspect-ratio: 16/9;
      overflow: hidden;
      animation: Opacity 10s ease;
    }
    .titre3 {
      grid-row: 1;
      display: flex;
      font-size: clamp(0.2rem, 3vw, 2.5rem);
      text-transform: capitalize;
      font-weight: 900;
      font-family: $font-4;
      align-items: flex-end;
      max-height: 60px;
      color: $color-5;

      &::after {
        content: ".";
        color: $color-1;
      }
    }

    p {
      margin-top: -10vh;
      animation: Droite_Gauche 5s ease;
    }
  }

  .Section-4 {
    padding: 0 15%;
    max-height: 65vh;
    background: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;

    .titre4 {
      grid-row: 1;
      display: flex;
      font-size: clamp(0.2rem, 3vw, 2.5rem);
      text-transform: capitalize;
      font-weight: 900;
      font-family: $font-4;
      align-items: flex-end;
      max-height: 60px;
      color: $color-5;
    }

    .titre4::after {
      content: ".";
      color: $color-1;
    }

    .titre4 {
      margin: 25px 0;
      z-index: 10;
    }

    .Petite-Photo-1 {
      grid-row: 2;
      grid-column: 1;
      height: 100%;
    }

    p {
      color: rgb(0, 0, 0);
    }

    .p-1 {
      margin: 0 25px;
      grid-row: 2;
      grid-column: 2;
      transform: translateY(-10vh);
    }
    .p-2 {
      padding: 25px 0;
      grid-row: 3;
      grid-column: 1;
    }
  }

  .Section-5 {
    padding: 0 15%;
  }

  .Section-6 {
    padding: 5% 15%;
    max-height: 65vh;
    background: $color-8;

    h3 {
      text-transform: uppercase;
      color: $color-1;
      text-align: center;
      font-family: $font-2;
      font-size: clamp(0.5rem, 1.5vw, 0.8rem);
      letter-spacing: 5px;
    }
    h2 {
      text-transform: uppercase;
      color: $color-3;
      text-align: center;
      font-family: $font-5;
      font-size: clamp(1rem, 1.5vw, 10rem);
      font-weight: 500;
      padding: 1%;
    }
    .sous-titre {
      text-transform: uppercase;
      color: $color-3;
      text-align: center;
      font-family: $font-5;
      font-size: clamp(0.5rem, 1.5vw, 0.8rem);
      font-weight: 500;
    }
  }

  @keyframes Haut_Bas {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes Droite_Gauche {
    0% {
      opacity: 0;
      transform: translateX(500px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes Gauche_Droite {
    0% {
      opacity: 0;
      transform: translateX(-500px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes Opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1000px) {
  .ProdFilms {
    padding-top: 20%;
    .Section-1,
    .Section-2,
    .Section-3,
    .Section-4 {
      padding: 0 5%;
      height: auto;
      display: flex;
      flex-direction: column;
    }

    iframe {
      width: 100%;
      border-radius: 15px;
      margin: 15px;
    }
    p {
      font-size: clamp(0.45rem, 1.5vw, 1.5rem);
      line-height: 0.7em;
    }

    .Section-2 {
      height: auto;

      iframe {
        border-radius: 300px;
        transform: translate(0px, -10px);
      }

      p {
        margin-top: -4vh;
        margin-bottom: 25px;
      }
    }

    .Section-3 {
      height: auto;
      p {
        margin-top: 0vh;
      }
    }

    .Section-4 {
      height: auto;
      align-items: flex-start;

      .p-1 {
        margin-top: 15px;
        transform: translateY(0vh);
      }
      .p-2 {
        padding: 25px;
        line-height: 1.2em;
      }
    }
  }
}
