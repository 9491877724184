.containt-footer {
  width: 100%;
  background: $color-4;
}

.footer {
  padding-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 5%;
  margin-right: 5%;
  gap: 20px;
  background: black;
}

.reseau-sociaux {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  a {
    height: 45px;
    width: 45px;
  }
}

.reseau-sociaux::after {
  content: "";
  width: 1px;
  height: 190px;
  border-right: 2px dotted $color-3;
}

.fab {
  background-color: $color-1;
  // padding: 10px;
  border-radius: 50%;
  margin-right: 20px;
  text-align: center;
  transition: 0.4s ease-in-out;
  line-height: 2em;
}

.fab:hover {
  background-color: $color-3;
  transform: scale(80%);
}

.pays {
  font-size: clamp(20px, 1vw, 25px);
  font-family: $font-3;
  font-weight: 400;
  color: $color-3;
  margin-bottom: 15px;
}

.etablissement {
  border-right: 2px dotted $color-3;
  font-size: clamp(20px, 1vw, 25px);
  font-family: $font-2;
  font-weight: 300;
  color: $color-5;
  line-height: 35px;
  .telephone {
    font-size: clamp(12px, 1vw, 16px);
    color: $color-2;
  }
}

.liens {
  font-size: clamp(20px, 1vw, 25px);
  font-family: $font-2;
  font-weight: 800;
  color: $color-2;
  line-height: 35px;
  cursor: pointer;

  .nav-active {
    color: $color-3;
  }

  h3 {
    color: $color-5;
    font-size: clamp(20px, 1vw, 25px);
    font-family: $font-2;
    font-weight: 800;
  }

  h3:hover {
    color: $color-1;
  }

  //BOUTON CHANGEMENT DE LANGUE
  button {
    font-family: $font-1;
    padding: 2%;
    background-color: transparent;
    border-radius: 5px;
    color: $color-2;
    cursor: none;
    outline: none;
    border: none;
    border: 1px solid white;
    padding: 5px;
  }
  button:hover {
    color: $color-1;
    border-color: $color-1;
  }
}

.inscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  input,
  button {
    height: 50px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: $color-3;
    padding: 10px;
    border: none;
    outline: none;
  }
  ::placeholder {
    color: $color-5;
  }
  input:focus {
    border: 2px solid $color-5;
  }
  button {
    color: $color-2;
    background-color: $color-3;
    text-transform: uppercase;
    cursor: none;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-left: 5%;
    margin-right: 5%;
    gap: 20px;
    background: black;
  }
  .reseau-sociaux::after {
    width: 0px;
    border-right: 0px dotted $color-3;
  }

  .etablissement {
    border-right: 0px dotted $color-3;
    font-size: 1rem;
    text-align: center;

    .telephone {
      font-size: 1.5rem;
      color: $color-2;
    }
  }

  .inscription {
    display: none;
  }

  .liens {
    text-align: center;
  }
}
