.navigation {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  padding: 10px 8%;
  top: 0;
  width: 100%;
  z-index: 5;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    height: 10vh;
    width: 100%;
    background: linear-gradient(180deg, black, transparent);
    overflow: hidden;
    pointer-events: none;
    z-index: -50;
  }

  a,
  .titre-avec-sous-menu {
    display: flex;
    position: relative;
    justify-content: end;
    align-items: center;
    // margin: 10px 5px;
    padding: 20px 2%;
    font-size: 1.5em;
    font-family: $font-4;
    font-weight: 100;
    border-radius: 8px;
  }

  .titre-avec-sous-menu {
    background: transparent;
    color: $color-2;
    border: none;
  }

  .nav-active {
    &::after {
      content: "";
      width: 85%;
      height: 2px;
      background: $color-1;
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.logo {
  align-items: center;
  width: 100%;
}

.menu {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .empty-layout {
    width: 10%;
  }
}

.titre-menu:hover {
  &::after {
    content: "";
    width: 85%;
    height: 2px;
    background: $color-1;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    animation: titre 0.01s ease;
  }
}

@keyframes titre {
  0% {
    width: 50%;
  }
  100% {
    width: 85%;
  }
}

.tools {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  // margin-right: 10%;

  button {
    font-family: $font-1;

    background-color: transparent;
    border-radius: 20%;
    color: $color-2;
    cursor: none;
    outline: none;
    border: none;
    border: 1px solid white;
    padding: 5px;
  }

  .far,
  .fas,
  button {
    padding: 2px;
    margin-right: 10px;
    margin-left: 10px;
  }

  button:hover {
    color: $color-1;
    border-color: $color-1;
  }
}

.fa-user:hover {
  color: $color-1;
  border-color: $color-1;
}

.fa-stream:hover {
  color: $color-2;
  background-color: $color-1;
  border-radius: 50%;
}

.menu-burger {
  display: none;
  width: 100%;

  span {
    left: 0;
    top: 0;
    font-size: 3em;
    text-align: center;
    justify-content: center;
  }

  .burger {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 0;

    a,
    .titre-avec-sous-menu {
      transform: scale(80%) translateY(5px);
    }
  }

  .sous-menu-burger {
    display: none;
    position: absolute;
    flex-direction: column;
    margin-top: 120px;
    font-size: 1rem;
    background-color: black;
    border-radius: 0px 0px 25px 25px;
  }

  .burger:hover > ul {
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  .navigation {
    display: block;

    a {
      padding: 0;
    }
    .nav-active {
      &::after {
        content: "";
        width: 105%;
        height: 5px;
        bottom: -2px;
      }
    }
  }

  .menu {
    display: none;
  }

  .menu-burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titre-menu:hover {
    &::after {
      content: "";
      width: 105%;
      bottom: -2px;
    }
  }
}
