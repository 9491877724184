.expertises {
  // padding: 0 8%;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(4, 0, 0, 0.3) 36%,
      transparent 100%
    ),
    url("../../assets/expertises/200121-developpeur-web.jpg") no-repeat
      center/cover;
}

.fa-caret-down {
  margin: 10px;
}
