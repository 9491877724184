.copyright {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  background: $color-4;

  .logo {
    opacity: 0.5;
    width: 10%;
  }

  .imglogo {
    width: 100%;
    transform: scale(100%);
    max-width: 100px;
  }

  .text {
    width: 70%;
    font-size: clamp(8px, 1vw, 16px);
    font-family: $font-2;
    font-weight: 100;
    color: $color-3;
    text-align: start;
    margin: auto;
  }
}

@media screen and (max-width: 1000px) {
  .copyright {
    .logo {
      display: block;
    }
  }
}
